<template>
  <div class="loader__wrapper">
    <a-spin size="large" />
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>
